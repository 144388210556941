import './static/scss/Home.scss';
import Circuits from './static/images/Circuits.png';
import GitHub from './static/svgs/icons8-github.svg';
import LinkedIn from './static/svgs/icons8-linkedin.svg';
import Slider from './components/Slider.js'
import data from './captions.json'

//console.log(data);
function App() {
  const slides = [
    {url: require('./static/images/Image0.jpg'), title: "Georgia Visit", caption: data.captions[0].body},
    {url: require('./static/images/Image1.jpg'), title: "Prom", caption: data.captions[1].body},
    {url: require('./static/images/Image2.jpg'), title: "GmoA", caption: data.captions[2].body},
    {url: require('./static/images/Image3.jpg'), title: "Georgia Tech Visit", caption: data.captions[3].body}
  ]
  return (
    <div className="App">
      <img src={Circuits} alt="" className="background"></img>
      <div className="nav">
        <a href="https://github.com/parkeranderson130"><img src={GitHub} alt="GitHub" className="social"/></a>
        <a href="https://www.linkedin.com/in/parker-anderson-baab0820a"><img src={LinkedIn} alt="GitHub" className="social"/></a>
        <a href="https://drive.google.com/file/d/1qdanfjeTAxatYW1Dtb1fuG3fH09gg0k2/view?usp=sharing"><p><i>See my resume &raquo;</i></p></a>
      </div>
      <div className="body">
        <Slider slides={slides} className="slider"></Slider>
        <div className="text">
          <h1>Parker Anderson</h1>
          <h3>
              <li><u>Computer Science</u> student.</li>
              <li><u>Web // Graphic</u> designer on the side.</li>
              <li>Chess grandmaster (in training).</li>
          </h3>
        </div>
      </div>
    </div>
  );
}

export default App;
