import React from "react";
import { useState } from "react";
import './styles/Slider.scss'

const Slider = ({slides}) => {
  const [index, setIndex] = useState(0);
  const getPrevious = () => {
    const isFirst = (index === 0);
    setIndex(isFirst ? slides.length - 1 : index - 1);
  };
  const getNext = () => {
    const isLast = (index === slides.length - 1);
    setIndex(isLast ? 0 : index + 1);
  };
  const text = slides[index].caption

    return (
        <div className="slide" style={{backgroundImage: `url(${slides[index].url})`}}>
          <button onClick={getPrevious}>&laquo;</button>
          <button onClick={getNext}>&raquo;</button>
          <div>
            <p>{text}</p>
          </div>
        </div>
    );
  };

export default Slider;